<template>
  <div class="repay">
    <BaseHeaderBar title="Pembayaran" :showCustomerService="false" :showBackButton="true" color="#333" bgColor="#FFF" />
    <div class="top-block">
      <p class="p1">Yang perlu dibayarkan</p>
      <p class="p2">Rp{{ amount }}</p>
      <p class="p3">Jenis pembayaran：{{ repaymentWay }}</p>
    </div>
    <div class="repay-sort-list">
      <div v-for="item in bankList" :key="item.id" @click="selectRepaySort(item)">
        <p>{{ item.name }}</p>
        <img class="arrow-right" src="@/assets/img/arrow-right.png" />
      </div>
    </div>

    <van-popup v-model="showPicker" round position="bottom">
      <div class="picker-toolbar">
        <div @click="showPicker = false" class="picker-cancel">
          <img src="@/assets/img/header-back-btn.png" />
        </div>
        Pilih metode pembayaran
      </div>
      <div class="repay-sort-picker-list">
        <div class="item" v-for="item in selectedPayWay.payway" :key="item.pay_way" @click="goPay(item)">
          <p class="label">{{ item.name }}</p>
          <img class="arrow-right" src="@/assets/img/arrow-right.png" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Popup } from 'vant'
import request from '@/utils/request'
import webviewBack from '@/mixins/webviewBack'

Vue.use(Popup)
const repayTypeMap = {
  1: 'Pelunasan',
  2: 'Perpanjangan'
}

export default {
  name: 'Repay',
  mixins: [webviewBack],
  data () {
    return {
      repaymentWay: '',
      amount: '',
      bankList: [],
      selectedPayWay: {},
      showPicker: false
    }
  },
  created () {
    this.getPaymentBankList()
    this.getQuery()
  },
  methods: {
    getPaymentBankList () {
      const postData = {
        redrpaymentType: this.$route.query.repayType
      }
      request.post('padrybankList', postData)
        .then(res => {
          this.bankList = res.padrymentbankList
        })
    },
    getQuery () {
      const { repayType, amount } = this.$route.query
      this.repaymentWay = repayTypeMap[repayType]
      this.amount = amount
    },
    selectRepaySort (item) {
      this.selectedPayWay = item
      this.showPicker = true
    },
    goPay (item) {
      const { repayType, amount, orderId, payID } = this.$route.query
      this.$router.push({
        path: '/pay',
        query: {
          repayType,
          amount,
          orderId,
          payID,
          channelId: this.selectedPayWay.pay_id,
          payChannel: this.selectedPayWay.pay_channel,
          name: this.selectedPayWay.name,
          payWay: item.pay_way,
          payWayName: item.name
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.repay {
  min-height: 100vh;
  background-color: @body-bg-color;
}

.top-block {
  width: 726px;
  height: 273px;
  padding-top: 67px;
  padding-bottom: 43px;
  margin: 14px 12px 24px;
  box-sizing: border-box;
  background: url(../assets/img/repay-top-block.png) no-repeat;
  background-size: cover;

  p {
    text-align: center;

    &:nth-child(n+2) {
      margin-top: 24px;
    }
  }

  .p1 {
    font-size: 26px;
    color: #333;
  }

  .p2 {
    font-size: 32px;
    font-weight: 500;
    color: #333;
  }

  .p3 {
    font-size: 24px;
    color: #999;
  }
}

.arrow-right {
  width: 20px;
  height: 24px;
}

.repay-sort-list {
  padding-top: 24px;
  padding-bottom: 24px;
  margin: 24px 30px 0;
  border-radius: 8px;
  background-color: #FFF;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    line-height: 88px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: 24px;
    margin-right: 24px;

    &:nth-child(n+2) {
      margin-top: 6px;
    }

    &:nth-child(odd) {
      background-color: @body-bg-color;
    }

    p {
      font-size: 26px;
      color: #333;
    }
  }
}

.picker-toolbar {
  position: relative;
  width: 100%;
  height: 88px;
  line-height: 88px;
  font-size: 30px;
  color: #333;
  text-align: center;
}

.picker-cancel {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 30px;
  cursor: pointer;

  img {
    width: 24px;
    height: 40px;
  }
}

.repay-sort-picker-list {
  padding: 24px 30px 206px;
  background-color: @body-bg-color;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    line-height: 88px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 8px;
    background-color: #FFF;

    &:nth-child(n+2) {
      margin-top: 18px;
    }

    p {
      font-size: 30px;
      color: #333;
    }
  }
}
</style>
